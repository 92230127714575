import React from "react";
import {
    MDBJumbotron,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBCardTitle,
    MDBCardImage,
    MDBCardBody,
    MDBCardText
} from "mdbreact";

const JumbotronPage = () => {
    return (
        <MDBContainer className="mt-5 text-center">
            <MDBRow>
                <MDBCol>
                    <MDBJumbotron className="text-center">
                        <MDBCardTitle className="card-title h4 pb-2">
                            <strong>About the Artist</strong>
                        </MDBCardTitle>
                        <MDBCardText>
                            I was born in 1968 in Detroit, Michigan. It’s hard to believe, but I now find myself a 50
                            year old man. The magic of my childhood grew from the love of my family and the sustaining
                            energy of imagination. Youth, to me, was TV guide clippings of blurry, black and white
                            Godzilla images and, when 1977 arrived, the technicolor splendor of Star Wars. Inspired
                            accordingly, some of the warmest memories that I have incorporate my grandfather’s, wind up
                            8mm movie camera. I lay on the basement floor for hours making stop motion movies with lumps
                            of clay and plastic models. I left all of that behind in a cardboard box, but I have never
                            grown up.
                        </MDBCardText>
                        <MDBCardText>
                            While I am now married to a wonderful woman, and I am a proud and thankful father of two
                            amazing daughters, I largely remain the adolescent running through backyards, drinking water
                            from the garden hose, and making stop motion movies in the basement. At best, my art is the
                            culmination of these experiences and the emotions that they elicit. The juxtaposition of my
                            adult life and the magic of childhood. My life continues to be blessed with the love of
                            family and friends, but when I step into my studio, I become young again. I hope that some
                            of the innocence and discovery, and that youthful joy of creation, remains in my work. I
                            hope that it conveys something equally personal for the viewer.
                        </MDBCardText>
                        <MDBCardImage
                            src='https://i.postimg.cc/5yqLZ1kk/collage.jpg'
                            className="img-fluid mt-3"
                        />
                        <MDBCardBody>
                            <MDBCol className="d-flex justify-content-center mt-2" md="12">
                                <MDBCol md="3" className="d-flex justify-content-around">
                                    <a href="https://www.instagram.com/russell_bloomfield/"><MDBIcon
                                        fab
                                        icon="instagram"
                                        className="grey-text"
                                        size="lg"
                                    /></a>
                                </MDBCol>
                            </MDBCol>

                        </MDBCardBody>
                    </MDBJumbotron>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default JumbotronPage;