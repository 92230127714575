import {get, post, del} from './axios';

function getAllPaintings() {
  return get('/api/paintings/');
}

function getPaintingWithId(paintingId){
    return get('/api/paintings/' + paintingId);
}

function savePainting(data){
    return post('/api/paintings/', data);
}

function deletePainting(paintingId){
    return del('/api/paintings/' + paintingId);
}

function updatePainting(paintingId, data){
    return post('/api/paintings/update/' + paintingId, data);
}

export default { getAllPaintings, getPaintingWithId, savePainting, deletePainting, updatePainting };