import React from 'react';
import {MDBContainer, MDBRow, MDBCol, MDBBtn, MDBView} from "mdbreact";
import {PaintingService} from "../../api";
import axios from 'axios';
import {Link} from "react-router-dom";

export default class Home extends React.Component {
    state = {
        paintings: []
    };

    async componentDidMount() {
        try {
            await this.getPaintings()
        } catch (err) {
            console.log(err);
        }
    }

    async getPaintings() {
        try {
            const paintings = await PaintingService.getAllPaintings();
            this.setState({paintings});
        } catch (err) {
            console.log('failed');
        }
    }

    deletePainting = async (paintingId, deleteHash, index) => {
        try {
            await PaintingService.deletePainting(paintingId);
            await axios.delete("https://api.imgur.com/3/image/" + deleteHash,
                {headers: {'Authorization': 'Client-ID 546c25a59c58ad7'}});
            let paintings = this.state.paintings;
            paintings.splice(index, 1);
            this.setState({paintings});
        } catch (err) {
            console.log('failed');
        }
    };

    render() {
        return (
            <MDBContainer>
                <h4 className="h3-responsive font-weight-bold text-center my-5">
                    Manage Paintings
                </h4>


                {this.state.paintings.map((painting, index) => (
                    <MDBRow>
                        <MDBCol md="6" className="p-4 justify-content-center align-self-center">
                            <MDBView hover>
                                <img
                                    src={painting.imageLink}
                                    className="img-fluid"
                                    alt={painting.name}

                                />
                            </MDBView>
                        </MDBCol>
                        <MDBCol md="6" className="p-4 justify-content-center align-self-center">

                            <MDBRow>
                                <MDBCol md="6">
                                    <p>{painting.name}</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6">
                                    <p>{painting.description}</p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="6">
                                    <p><i>Index: {painting.index ? painting.index : "Not set"}</i></p>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <div className="text-center pt-5 text-md-left">
                                    <Link to={"/admin/editPainting/" + painting._id}><MDBBtn color="blue" size="md">
                                        Edit Details
                                    </MDBBtn></Link>
                                </div>
                                <div className="text-center pt-5 text-md-left">
                                    <MDBBtn color="red" size="md" onClick={() => {
                                        this.deletePainting(painting._id, painting.deleteHash, index)
                                    }}>
                                        Delete
                                    </MDBBtn>
                                </div>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>

                ))}
            </MDBContainer>
        );
    }
}
