import React from "react";
import {MDBContainer, MDBRow, MDBCol, MDBView, MDBBtn, MDBInput} from "mdbreact";
import {PaintingService} from "../../api";
import {Link} from "react-router-dom";

export default class SinglePainting extends React.Component {
    state = {
        painting: null,
        error: null
    };

    async componentDidMount() {
        const {paintingId} = this.props.match.params;
        try {
            await this.getSinglePainting(paintingId)
        } catch (err) {
            console.log(err);
        }
    }

    async getSinglePainting(paintingId) {
        try {
            const painting = await PaintingService.getPaintingWithId(paintingId);
            this.setState({painting});
        } catch (err) {
            console.log('failed');
        }
    }

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => ({
            painting: {
                ...prevState.painting,
                [name]: value
            }
        }));
    };

    submitForm = async (event) => {
        try {
            await PaintingService.updatePainting(this.state.painting._id, {
                name: this.state.painting.name,
                description: this.state.painting.description,
                index: this.state.painting.index
            });
            window.location.reload();
        } catch (err) {
            console.log('failed');
        }
    };

    render() {
        const {painting} = this.state;
        return (
            <MDBContainer style={{paddingTop: "5%"}}>
                {painting && <MDBRow>
                    <MDBCol md="6" className="p-4 justify-content-center align-self-center">
                        <MDBView hover>
                            <img
                                src={painting.imageLink}
                                className="img-fluid"
                                alt={painting.name}

                            />
                        </MDBView>
                    </MDBCol>
                    <MDBCol md="6" className="p-4 justify-content-center align-self-center">

                        <MDBRow>
                            <MDBCol md="4">
                                <MDBInput type="textarea" name="name" value={this.state.painting.name}
                                          onChange={this.changeHandler} label="Name"/> </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="4">
                                <MDBInput type="textarea" name="description" value={this.state.painting.description}
                                          onChange={this.changeHandler} label="Description"/> </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md="4">
                                <MDBInput type="text" name="index" value={this.state.painting.index}
                                          onChange={this.changeHandler} label="Index"/> </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <div className="text-center pt-5 text-md-left">
                                <MDBBtn color="green" size="md" onClick={this.submitForm}>
                                    Save
                                </MDBBtn>
                            </div>
                            <div className="text-center pt-5 text-md-left">
                                <Link to="/admin/managePaintings"><MDBBtn color="grey" size="md">
                                    Back
                                </MDBBtn></Link>
                            </div>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>}
                {this.state.error}
            </MDBContainer>

        );
    }
}
