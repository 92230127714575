import React from "react";
import {MDBContainer, MDBRow, MDBCol} from "mdbreact";
import {PaintingService} from "../../api";

export default class SinglePainting extends React.Component {
    state = {
        painting: null,
        error: null
    };

    async componentDidMount() {
        const {paintingId} = this.props.match.params;
        try {
            await this.getSinglePainting(paintingId)
        } catch (err) {
            console.log(err);
        }
    }

    async getSinglePainting(paintingId) {
        try {
            const painting = await PaintingService.getPaintingWithId(paintingId);
            this.setState({painting});
        } catch (err) {
            console.log('failed');
        }
    }

    render() {
        const {painting} = this.state;
        return (
            <MDBContainer style={{paddingTop: "5%"}}>
                {painting && <MDBRow>
                    <MDBCol md="8">
                        <img
                            src={painting.imageLink}
                            className="img-fluid"
                            alt={painting.name}
                        />
                    </MDBCol>
                    <MDBCol md="4">
                        <h3 className="p-3"><b>{painting.name}</b></h3>
                        <p>{painting.description}</p>
                    </MDBCol>
                </MDBRow>}
                {this.state.error}
            </MDBContainer>

        );
    }
}
