import React from "react";
import {MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput} from "mdbreact";
import {EmailService} from "../../api";

export default class ContactPage extends React.Component {
    state = {
        contactName: "",
        contactEmail: "",
        subject: "",
        message: ""
    };

    sendEmail = async event => {
        try {
            await EmailService.sendEmail({
                contactName: this.state.contactName,
                contactEmail: this.state.contactEmail,
                subject: this.state.subject,
                message: this.state.message
            });
            window.location.reload();
        } catch (err) {
            console.log('failed to send email');
            console.log(err);
            window.location.reload();
        }

    };

    changeHandler = event => {
        const name = event.target.name;
        let value = event.target.value;
        this.setState(prevState => ({
            [name]: value
        }));
    };

    render() {
        return (
            <MDBContainer>
                <h2 className="h2-responsive font-weight-bold text-center my-5">
                    Contact Me
                </h2>
                <MDBRow>
                    <MDBCol className="md-0 mb-5">
                        <form>
                            <MDBRow>
                                <MDBCol md="6">
                                    <div className="md-form mb-0">
                                        <MDBInput type="text" name="contactName" id="contactName" label="Your name"
                                                  value={this.state.contactName}
                                                  onChange={this.changeHandler}/>
                                    </div>
                                </MDBCol>
                                <MDBCol md="6">
                                    <div className="md-form mb-0">
                                        <MDBInput
                                            type="text"
                                            id="contactEmail"
                                            name="contactEmail"
                                            label="Your email"
                                            value={this.state.contactEmail}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="12">
                                    <div className="md-form mb-0">
                                        <MDBInput type="text" id="subject" name="subject" label="Subject"
                                                  value={this.state.subject}
                                                  onChange={this.changeHandler}/>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="12">
                                    <div className="md-form mb-0">
                                        <MDBInput
                                            type="textarea"
                                            id="message"
                                            name="message"
                                            label="Your message"
                                            value={this.state.message}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </form>
                        <div className="text-center text-md-left">
                            <MDBBtn color="blue" size="md" onClick={this.sendEmail}>
                                Send
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}