import React, {Component} from "react";
import {MDBContainer, MDBCard, MDBCardBody} from "mdbreact";

class CollapsePage extends Component {

    render() {
        return (
            <MDBContainer>
                <h3 className="pt-5">Artist Statement</h3>
                <MDBContainer className='md-accordion'>
                    <MDBCard>
                        <MDBCardBody>
                            I seek the elusive past. The emotions and memories that arrive to each of us unexpected,
                            uncontrolled, and fleeting. I strive to capture the wonder of discovery and imagination and
                            the joy of creation in my paintings. My images are often derived from source material,
                            mainly photographs, that are personal to my life and my friends and family. On occasion, I
                            rely on the pop imagery that is integral to my development. I hope that the paintings that I
                            create, while personal in nature, convey something familiar and welcoming to the viewer as
                            well.
                        </MDBCardBody>
                    </MDBCard>
                </MDBContainer>
            </MDBContainer>
        );
    }
}

export default CollapsePage;