import React, {Component} from 'react';
import Home from './components/home';
import Navigation from './components/navbar'
import Contact from './components/contact'
import About from './components/about'
import ArtistStatement from './components/artist-statement'
import SinglePainting from './components/single-painting'
import AddPainting from './components/add-painting'
import ManagePaintings from './components/manage-paintings'
import EditPainting from './components/edit-painting'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './index.css';
import 'react-image-lightbox/style.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';

class App extends Component {
    render() {
        return (
            <div className="App">
                <Router>
                    <div>
                        <Navigation/>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/artist-statement" component={ArtistStatement} />
                        <Route exact path="/painting/:paintingId" component={SinglePainting}/>
                        <Route exact path="/admin/addPainting" component={AddPainting}/>
                        <Route exact path="/admin/managePaintings" component={ManagePaintings}/>
                        <Route exact path="/admin/editPainting/:paintingId" component={EditPainting}/>
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
