import React from 'react';
import {
    MDBSideNav,
    MDBSideNavNav,
    MDBSideNavCat,
    MDBSideNavLink,
    MDBBtn,
    MDBIcon,
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink
} from 'mdbreact';
import {Link} from "react-router-dom";

class SideNavPage extends React.Component {
    state = {
        isOpen: false
    };

    toggleCollapse = () => {
        this.setState({isOpen: !this.state.isOpen});
    };

    handleToggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    render() {
        return (
            <div>
                <MDBNavbar double={true} color="blue-gradient" dark expand="lg"
                           className="navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav"
                >
                    <MDBNavbarBrand>
                        <MDBBtn onClick={this.handleToggle}><MDBIcon icon="bars" size="5x"/></MDBBtn>
                    </MDBNavbarBrand>
                    <div className="breadcrumb-dn mr-auto">
                        <Link to="/"><strong className="white-text">Russell Bloomfield Studios</strong></Link>
                    </div>
                    <MDBNavbarNav right className="nav-flex-icons ml-auto">
                        <MDBNavItem>
                            <MDBNavLink to="/"><i className="fas fa-paint-brush"/><span
                                className="clearfix d-none d-sm-inline-block">Paintings</span></MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink to="/contact"><i className="fas fa-envelope"/><span
                                className="clearfix d-none d-sm-inline-block">Contact</span></MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink to="/about"><i className="fas fa-eye"/><span
                                className="clearfix d-none d-sm-inline-block">About the Artist</span></MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink to="/artist-statement"><i className="fas fa-scroll"/><span
                                className="clearfix d-none d-sm-inline-block">Artist Statement</span></MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBNavbar>
                <MDBSideNav
                    hidden
                    triggerOpening={this.state.isOpen}
                    breakWidth={1400}
                    className="morpheus-den-gradient"
                    responsive={true}
                    showOverlay={true}
                >
                    <img
                        className="img-fluid d-flex justify-content-center mx-auto pb-3"
                        src="https://i.postimg.cc/Kjnj3NL3/IMG-1007.png"/>
                    <h6>Russell Bloomfield Studios</h6>
                    <li>
                        <ul className="social">
                            <li>
                                <a href="https://www.instagram.com/russell_bloomfield/">
                                    <MDBIcon fab icon="instagram"/>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <MDBSideNavNav>
                        <MDBSideNavLink to="/"><i className="fas fa-home"/>Home</MDBSideNavLink>
                        <MDBSideNavCat
                            name="Paintings"
                            id="paintings"
                            icon="paint-brush"
                        >
                            <MDBSideNavLink to="/">All Paintings</MDBSideNavLink>
                        </MDBSideNavCat>
                        <MDBSideNavCat name="About" id="about" icon="eye">
                            <MDBSideNavLink to="/about">About the Artist</MDBSideNavLink>
                            <MDBSideNavLink to="/artist-statement">Artist Statement</MDBSideNavLink>
                        </MDBSideNavCat>
                        <MDBSideNavCat name="Contact" id="contact" iconRegular icon="envelope">
                            <MDBSideNavLink to="/contact">Write a message</MDBSideNavLink>
                        </MDBSideNavCat>
                    </MDBSideNavNav>
                </MDBSideNav>
            </div>
        );
    }
}

export default SideNavPage;