import axios from 'axios';
// import { CustomerStore } from '../store/customer';
const config = {
  baseUrl: 'https://russell-art-server.herokuapp.com'
  // baseUrl: 'http://localhost:8081'
};

axios.interceptors.response.use(
  response => {
    const token = response.headers['x-authorization'];
    if (token) {
      localStorage.setItem('x-token', token);
      // CustomerStore.setToken(token);
    }
    return response.data;
  },
  error => {
    if (error.response.data.message) {
      return Promise.reject({
        message: error.response.data.message,
        status: error.status
      });
    }
    return Promise.reject(error);
  }
);

// axios.interceptors.request.use(
//   response => {
//     const token = localStorage.getItem('x-token');
//     if (token) {
//       response.headers['x-authorization'] = token;
//     }
//     return response;
//   },
//   error => Promise.reject(error)
// );

const baseUrl = url => config.baseUrl + url;

export const post = (url, data, config) => {
  return axios.post(baseUrl(url), data, config);
};

export const put = (url, data, config) => {
  return axios.put(baseUrl(url), data, config);
};

export const get = (url, config) => {
  return axios.get(baseUrl(url), config);
};

export const del = (url, config) => {
  return axios.delete(baseUrl(url), config);
};
