import React from 'react';
import {MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn} from "mdbreact";
import {PaintingService} from "../../api";
import axios from 'axios';

export default class Home extends React.Component {
    state = {
        painting: {}
    };

    changeHandler = event => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState(prevState => ({
            painting: {
                ...prevState.painting,
                [name]: value
            }
        }));
    };

    fileChangeHandler = event => {
        const name = event.target.name;
        let file = event.target.files[0];
        let reader = new FileReader();

        if (event.target.files.length === 0) {
            return;
        }

        reader.onloadend = () =>
            this.setState(() => ({
                [name]: reader.result,
            }));
        reader.readAsDataURL(file);
    };

    submitForm = async (event) => {
        try {
            let dataInBase64 = this.state.image.replace("data:image/png;base64,", "");
            dataInBase64 = dataInBase64.replace("data:image/jpeg;base64,", "");
            dataInBase64 = dataInBase64.replace("data:image/jpg;base64,", "");

            let data = {
                image: dataInBase64,
                album: "ZuTIIbmXxRw7G1k",
            };

            const imgurResponse = await axios.post("https://api.imgur.com/3/image", data,
                {headers: {'Authorization': 'Client-ID 546c25a59c58ad7'}});

            await PaintingService.savePainting({
                imageLink: imgurResponse.data.link,
                imgurId: imgurResponse.data.id,
                deleteHash: imgurResponse.data.deletehash,
                name: this.state.painting.name,
                description: this.state.painting.description,
            });
            this.setState({painting: {}});
            window.location.reload();

        } catch (err) {
            console.log('failed');
        }
    };

    render() {
        return (
            <MDBContainer>
                <h2 className="h1-responsive font-weight-bold text-center my-5">
                    Add New Painting
                </h2>
                <MDBRow>
                    <MDBCol className="md-0 mb-5">
                        <form>
                            <MDBRow>
                                <MDBCol md="12">
                                    <MDBInput type="text" name="name" value={this.state.painting.name}
                                              onChange={this.changeHandler} label="Name"/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="12">
                                    <MDBInput type="textarea" name="description" value={this.state.painting.description}
                                              onChange={this.changeHandler} label="Description"/>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol md="12">
                                    <MDBInput type='file' accept="image/*"
                                              name="image"
                                              onChange={this.fileChangeHandler}/>
                                </MDBCol>
                            </MDBRow>
                        </form>
                        <div className="text-center pt-5 text-md-left">
                            <MDBBtn color="green" size="md" onClick={this.submitForm}>
                                Save
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}
