import React from 'react';
import {MDBCol, MDBContainer, MDBMask, MDBRow, MDBView} from "mdbreact";
import Lightbox from "react-image-lightbox";
import {PaintingService} from "../../api";

export default class Home extends React.Component {
    state = {
        paintings: [],
        photoIndex: 0,
        isOpen: false
    };

    async componentDidMount() {
        try {
            await this.getPaintings()
        } catch (err) {
            console.log(err);
        }
    }

    async getPaintings() {
        try {
            const paintings = await PaintingService.getAllPaintings();
            this.setState({paintings});
        } catch (err) {
            console.log('failed');
        }
    }

    render() {
        const {paintings, photoIndex, isOpen} = this.state;
        return (
            <MDBContainer style={{paddingTop: "5%"}}>
                {/*<div className="mdb-lightbox">*/}
                <MDBRow>
                    {paintings.map((painting, index) => (
                        <MDBCol md="4" className="p-4 justify-content-center align-self-center">
                            <MDBView hover>
                                <img
                                    src={painting.imageLink}
                                    className="img-fluid"
                                    alt={painting.name}

                                />
                                <MDBMask className="flex-center" overlay="indigo-strong" onClick={() =>
                                    this.setState({photoIndex: index, isOpen: true})
                                } style={{cursor: "zoom-in"}}>
                                    <div>
                                        <h4 className="white-text">{painting.name}</h4><br/>
                                        <p className="white-text">{painting.description}</p>
                                    </div>
                                </MDBMask>
                            </MDBView>
                        </MDBCol>
                    ))}
                </MDBRow>
                {/*</div>*/}
                {isOpen && (
                    <Lightbox
                        mainSrc={paintings[photoIndex].imageLink}
                        nextSrc={paintings[(photoIndex + 1) % paintings.length].imageLink}
                        prevSrc={paintings[(photoIndex + paintings.length - 1) % paintings.length].imageLink}
                        imageTitle={photoIndex + 1 + "/" + paintings.length + " - " + paintings[photoIndex].name}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + paintings.length - 1) % paintings.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % paintings.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}